import {Directive, ElementRef, forwardRef, HostListener, inject, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DvbDateUtil, type FunctionType, isNullish, isPresent} from '@dv/shared/code';
import moment from 'moment';

const MAX_MINUTES = 60;
const MAX_MINUTES_SINGLE_DIGIT = 6;
const MIDNIGHT = 24;

/**
 * Used to display an ngModel containing minutes as hh:mm within a text input.
 */
@Directive({
    selector: '[dvMinutesInput][ngModel]',
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MinutesInputDirective),
            multi: true,
        },
    ],
})
export class MinutesInputDirective implements ControlValueAccessor {

    private readonly elementRef = inject(ElementRef);
    private readonly renderer = inject(Renderer2);

    public onChange?: FunctionType;
    public onTouched?: FunctionType;

    @HostListener('keydown.enter')
    @HostListener('blur')
    private confirmValue(): void {

        const inputValue = this.elementRef.nativeElement.value;
        const parsed = this.parse(inputValue);
        const minutes = isPresent(parsed) ? DvbDateUtil.getMinutesSinceMidnight(parsed) : null;

        // write minutes to input
        this.writeValue(minutes);

        // write minutes to model
        this.onChange?.(minutes);
    }

    public writeValue(modelValue: any): void {
        if (isNullish(modelValue)) {
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', '00:00');

            return;
        }

        const hours = Math.floor(modelValue / DvbDateUtil.MINUTES_PER_HOUR);
        const minutes = Math.round(modelValue % DvbDateUtil.MINUTES_PER_HOUR);

        this.renderer.setProperty(
            this.elementRef.nativeElement,
            'value',
            `${this.pad(hours)}:${this.pad(minutes)}`);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Parses a value like '10:00' into a moment object. Understands many ways of typing in the time. Gracefully
     * falls back to defaults and eventually returns null when it cannot make out at all what time was meant to be
     * entered.
     */
    private parse(value: string): moment.Moment | null {
        if (isNullish(value)) {
            return null;
        }

        const digits = value.replace(/[^\d]/g, '');

        if (digits.length === 0) {
            return null;
        }

        if (digits.length === 1) {
            const hour1digit = this.parseHour(digits);

            return this.toMoment(hour1digit, 0);
        }

        const firstDigit = digits.substring(0, 1);
        const firstDigitNumber = parseInt(firstDigit, 10);

        if (digits.length === 2) {
            const hour2digits = this.parseHour(digits);

            if (hour2digits !== null) {
                return this.toMoment(hour2digits, 0);
            }
        } else if (firstDigitNumber >= 0 && firstDigitNumber <= 2) {
            const hour = this.parseHour(digits.substring(0, 2));
            const maxMinuteDigits = 4;
            const minutes = this.parseMinutes(digits.substring(2, Math.min(digits.length, maxMinuteDigits)));

            return this.toMoment(hour, minutes);
        }

        const maxMinuteDigits = 3;
        const minutesAfter = this.parseMinutes(digits.substring(1, Math.min(digits.length, maxMinuteDigits)));

        return this.toMoment(this.parseHour(firstDigit), minutesAfter);
    }

    private parseHour(digits: string): number | null {
        const hour = parseInt(digits, 10);

        if (hour < MIDNIGHT) {
            return hour;
        }

        if (hour === MIDNIGHT) {
            return 0;
        }

        return null;
    }

    private parseMinutes(digits: string): number | null {
        const minutes = parseInt(digits, 10);

        if (minutes < MAX_MINUTES) {
            if (minutes < MAX_MINUTES_SINGLE_DIGIT && digits.length < 2) {
                // If the minutes are '5', '4', '3', '2', '1' or '0' we multiply thenfold
                // This results in 50, 40, 30 and so on
                return minutes * 10;
            }

            return minutes;
        }

        return null;
    }

    private toMoment(hour: number | null, minutes: number | null): moment.Moment | null {
        if (hour === null || minutes === null) {
            return null;
        }

        return moment({hour, minute: minutes});
    }

    private pad(number: number): string {
        return String(number).padStart(2, '0');
    }
}
